/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Link } from 'react-router-dom'
const projects = [
  {
    name: 'Sensivo',
    role: 'CTO & fullstack developer',
    imageUrl: 'content/sensivo.png',
    url: 'https://sensivo.eu',
  },
  {
    name: 'YourHabit.me',
    role: 'Founder & developer',
    imageUrl: 'content/yourhabit.png',
    url: 'https://yourhabit.me',
  },
  {
    name: 'Lagom (Garmin watch face)',
    role: 'Founder & developer',
    imageUrl: 'content/lagom.png',
    url: 'https://apps.garmin.com/en-US/apps/37e8ef0a-e117-4eaf-88e4-8ef1fdc7d697',
  },
]

export default function Projects() {
  return (
    <div className=''>
      <div className='mx-auto py-0 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-12'>
        <div className='space-y-12'>
          <div className='space-y-5 sm:space-y-4'>
            <h2 className='text-3xl font-extrabold tracking-tight sm:text-4xl'>
              Projects
            </h2>
            <p className='text-xl text-gray-500 dark:text-gray-300'>
              The projects that I have worked on or currently working on.
            </p>
          </div>
          <ul
            role='list'
            className='space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8'
          >
            {projects.map((person, index) => (
              <li key={person.name}>
                {index !== 2 && (
                  <a
                    href={person.url}
                    target='_blank'
                    className='cursor-pointer hover:scale-105 transform transition-transform block'
                  >
                    <div className='space-y-4'>
                      <div className='aspect-w-3 aspect-h-2'>
                        <img
                          className='object-cover border bg-white dark:bg-gray-800 border-gray-100 dark:border-gray-800 rounded-lg'
                          src={person.imageUrl}
                          alt=''
                        />
                      </div>

                      <div className='space-y-2'>
                        <div className='text-lg leading-6 font-medium space-y-1'>
                          <h3>{person.name}</h3>
                          <p className='text-gray-600'>{person.role}</p>
                        </div>
                      </div>
                    </div>
                  </a>
                )}
                {index === 2 && (
                  <Link to='/lagomwf'>
                    <div className='cursor-pointer hover:scale-105 transform transition-transform block'>
                      <div className='space-y-4'>
                        <div className='aspect-w-3 aspect-h-2'>
                          <img
                            className='object-cover border bg-white dark:bg-gray-800 border-gray-100 dark:border-gray-800 rounded-lg'
                            src={person.imageUrl}
                            alt=''
                          />
                        </div>

                        <div className='space-y-2'>
                          <div className='text-lg leading-6 font-medium space-y-1'>
                            <h3>{person.name}</h3>
                            <p className='text-gray-600'>{person.role}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
