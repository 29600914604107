import React from 'react'
import { Nav } from '../components/Nav'

export const Home = () => {
	return (
    <div>
      {/* <div className='max-w-3xl mx-auto'> */}
      <div className='mx-auto py-12 px-4 max-w-3xl sm:px-6 lg:px-8 lg:py-12'>
        <img
          className='w-44 h-44 rounded-full mx-auto mb-8'
          src='/ava.jpeg'
          alt=''
        />
        <h2 className='text-2xl sm:text-4xl font-extrabold tracking-tig'>
          Aleksandr Skobeltcyn
        </h2>
        <p className='mt-8 text-gray-500 dark:text-gray-300 text-lg sm:text-xl text-center'>
          Hi, I'm a Sweden-based software engineer with
          more than ten years of experience and an entrepreneurial mindset.
        </p>

      <Nav></Nav>
    </div>
    </div>
  )
}
