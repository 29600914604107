/* This example requires Tailwind CSS v2.0+ */
const posts = [
  {
    title: 'Reality about app development with Flutter. Part 2',
    href: 'https://dev.to/skob/fuh-the-app-is-done-but-what-next-part-2-flutter-co-pilot-funnel-gdpr-and-store-fees-d7e',
    category: { name: 'Article', href: '#' },
    description:
      'Today we will talks about: How to significantly speed up your App development.Reality about Flutter for IOS',
    date: 'Aug 27, 2021',
    imageUrl: '/content/yourhabit-p-2.png',
    readingTime: '7 min',
    author: {
      name: 'Roel Aufderehar',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    title: '10 Steps that will help you turn your idea into a product. Part 1',
    href: 'https://dev.to/skob/10-steps-that-will-help-you-turn-your-idea-into-a-product-part-1-2g31',
    category: { name: 'Article', href: '#' },
    description:
      'Do you remember how you got the 💎idea and want to make it but do nott know how to approach it? Today I will share the whole process of it.',
    date: 'Jun 22, 2021',
    imageUrl: '/content/yourhabit-p-1.png',
    readingTime: '5 min',
  },
  {
    title: 'Reachable dreams. Step-by-step',
    href: 'https://skob.medium.com/reachable-dreams-step-by-step-80572a6a0ef6',
    category: { name: 'Personal experiment', href: '#' },
    description:
      "What was your plan for 2020? Become richer, healthier, smarter, or maybe find love? Let's dive into my experiment of 2020...",
    date: 'Dec 15, 2020',
    imageUrl: '/content/3.jpg',
    readingTime: '6 min',
  },
  {
    title: 'What have I learned from 4 startups failures?',
    href: 'https://skob.medium.com/what-have-i-learned-from-5-startups-failures-dfc7fe7c4eeb',
    category: { name: 'Article', href: '#' },
    description:
      'I am a person who always dreamed about building big things. That one day an idea will pop up in my mind and I will become a millionaire. Nowadays, especially in the IT world, such dreams can indeed come true, but is your dream real?',
    date: 'Oct 1, 2020',
    imageUrl: '/content/2.jpg',
    readingTime: '6 min',
  },
  {
    title: '10 insights I wish I had known as a junior full-stack developer',
    href: 'https://dev.to/skob/10-insights-i-wish-i-had-known-as-a-junior-full-stack-developer-11kc',
    category: { name: 'Article', href: '#' },
    description:
      'At Sensivo, we constantly try to improve our work, work methods, and work surroundings. Today I will share the top 10 hacks/tools that we use every day. Let’s jump right into it.',
    date: 'Dec 6,2019',
    imageUrl: '/content/1.jpg',
    readingTime: '6 min',
  },
]

export default function Example() {
  return (
    <div>
      <div className='mx-auto py-0 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-12'>
        <div className='space-y-12'>
          <div className='space-y-5 sm:space-y-4'>
            <h2 className='text-3xl font-extrabold tracking-tight sm:text-4xl'>
              Blog
            </h2>
            <p className='text-xl text-gray-500 dark:text-gray-300'>
              My blog is about tech/startups and self-development.
            </p>
          </div>

          <div className='mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none text-left'>
            {posts.map((post) => (
              <a
                href={post.href}
                target='_blank'
                key={post.title}
                className='cursor-pointer hover:scale-105 transform transition-transform block flex flex-col rounded-lg shadow-lg overflow-hidden'
              >
                <div className='flex-shrink-0'>
                  <img
                    className='h-48 w-full object-cover'
                    src={post.imageUrl}
                    alt=''
                  />
                </div>
                <div className='flex-1 bg-white dark:bg-gray-800 p-6 flex flex-col justify-between'>
                  <div className='flex-1'>
                    <p className='text-sm font-medium text-indigo-600 dark:text-indigo-200'>
                      <div className='hover:underline'>
                        {post.category.name}
                      </div>
                    </p>
                    <a href={post.href} className='block mt-2'>
                      <p className='text-xl font-semibold'>{post.title}</p>
                      <p className='mt-3 text-base text-gray-500 dark:text-gray-300'>
                        {post.description}
                      </p>
                    </a>
                  </div>
                  <div className='mt-6 flex items-center'>
                    <div className=''>
                      <div className='flex space-x-1 text-sm text-gray-500 dark:text-gray-300'>
                        <time dateTime={post.datetime}>{post.date}</time>
                        <span aria-hidden='true'>&middot;</span>
                        <span>{post.readingTime} read</span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
