/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
const features = [
  {
    name: '1. Get a special KEY from weather provider',
    description: 'It is free, but require to create your own account.',
    imageSrc: '/lagom/weather-step-1.png',
    links: [
      {
        href: 'https://home.openweathermap.org/users/sign_up',
        text: 'Create account',
      },
      {
        href: 'https://home.openweathermap.org/api_keys',
        text: 'Get key here',
      },
    ],
    actions: [
      'Create the account',
      'Verify your email',
      'Get your API key',
      'Wait 2 hours for key activation before use',
    ],
  },
  {
    name: '2. Go for a walk',
    // description: 'We need some how get the watch location for showing relevent weather.',
    description:
      'Start the activity that will catch the GPS signal. Watch face should initialize your location to get the weather. Watch face has no access to your position, but can check where the last activity has been done. Now we can get accurate weather information.',
    imageSrc: '/lagom/weather-step-2.png',
  },
  {
    name: '3. Add weather API to Connect IQ settings',
    description:
      'Cool, we have a Location and now need to provide a Key to get the weather.',
    imageSrc: '/lagom/weather-step-3.png',
    actions: [
      'Open Connect IQ and choose Lagom watch face',
      'Go to setting and scroll till Open Weather API key',
      'Insert that key and click "Save"',
      'You are done! Relax, gathering weather first time might take time',
    ],
  },
]

export default function Example() {
  return (
    <div className='text-left'>
      <div className='max-w-7xl mx-auto py-8 sm:py-8 sm:px-2 lg:px-4'>
        <div className='max-w-2xl mx-auto px-4 lg:max-w-none'>
          <div className='max-w-3xl text-left'>
            <p className='mt-2 text-3xl font-extrabold tracking-tight  sm:text-4xl'>
              If weather shows: N/A
            </p>
            <p className='mt-4 text-gray-500'>
              Skip this step if you have a modern Garmin device. Otherwise, I
              built a custom weather solution. It will require extra steps to
              set up and might feel difficult, but in general, it is only 3
              steps:
            </p>
            <ul className='pl-5 pt-2'>
              <li>1. Get key from Weather provider</li>
              <li>2. Get GPS location first time</li>
              <li>
                3. Add Key to Connect IQ, so the system will get weather data
                for your location
              </li>
            </ul>
          </div>

          <div className='space-y-16 pt-10 mt-10 border-t border-gray-200 sm:pt-16 sm:mt-16'>
            {features.map((feature) => (
              <div
                key={feature.name}
                className='flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8 lg:items-center'
              >
                <div className='mt-6 lg:mt-0 lg:col-span-5 xl:col-span-4'>
                  <h3 className='text-lg font-medium '>{feature.name}</h3>
                  <p className='mt-2 text-lg text-gray-500'>
                    {feature.description}
                  </p>
                  {feature.actions && (
                    <div className='mt-6'>
                      {feature.actions.map((action, index) => (
                        <p className='text-base text-gray-500'>
                          {index + 1}
                          {'. '}
                          {action}
                        </p>
                      ))}
                    </div>
                  )}
                  {feature.links && (
                    <div className='mt-6'>
                      {feature.links.map((link) => (
                        <p className='text-lg text-blue-500'>
                          <a href={link.href}>{link.text}</a>
                        </p>
                      ))}
                    </div>
                  )}
                </div>
                <div className='flex-auto lg:col-span-7 xl:col-span-8'>
                  <div className='aspect-w-5 aspect-h-2 rounded-lg bg-gray-100 overflow-hidden'>
                    <img
                      src={feature.imageSrc}
                      className='object-center object-fill'
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
