import { StarIcon } from '@heroicons/react/solid'
import LagomLayout from '../components/LagomLayouts'
import LagomFAQ from '../components/LagomFAQ'
import LagomWeather from '../components/LagomWeather'
import LagomPricing from '../components/LagomPricing'
import LagomFeatures from '../components/LagomFeatures'
import ReactGA from 'react-ga'
import React, { useState } from 'react'

export default function Lagom() {
  const Paddle = window.Paddle
  const openCheckout = () => {
    try {
      ReactGA.event({
        category: 'lagom',
        action: 'clickOnProButton',
        lable: 'getProPack',
      })
    } catch (e) {
      console.error('Error with GA')
    }
    Paddle.Checkout.open({ product: 751937 })
  }

  const [showForm, setShowForm] = useState(false)
  const [checkoutId, setCheckoutId] = useState('')
  const [code, setCode] = useState('')

  const getNewCode = async () => {
    // make a request to the server
    try {
      let result = await fetch(`https://check.skob.io/check/${checkoutId}`)
      var data = await result.json()

      if (data !== 'failure') {
        setCode('New code:' + data)
      } else {
        setCode(
          'Ops, we can not find your order number, please try again or contact support'
        )
      }
    } catch (error) {
      setCode(
        'Ops, we can not find your order number, please try again or contact support'
      )
    }
  }

  return (
    <div className=''>
      <div className='mx-auto py-0 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-12'>
        <div className=''>
          <img src='/lagom/top-screen.png' alt='' />
          <div className='text-4xl sm:text-7xl font-bold mt-5 sm:mt-10'>
            Lagom
          </div>
          <div className='text-2xl sm:text-3xl font-semibold mt-5'>
            with the best readability
          </div>
          <div className='mt-10 flex space-x-5 justify-center'>
            <a
              target='_blank'
              type='button'
              className='inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              href='https://apps.garmin.com/en-US/apps/37e8ef0a-e117-4eaf-88e4-8ef1fdc7d697'
            >
              Install on your watch
            </a>

            <div
              onClick={openCheckout}
              className='cursor-pointer relative inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-400 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400'
            >
              Get a Pro Pack
            </div>
          </div>
        </div>

        <div className='mt-2 text-sm max-w-md mx-auto text-gray-500'>
          By purchasing Pro, you support a developer and get a{' '}
          <span className='font-bold'>code</span> that unlocks certain features
          marked as 'Pro'. If you change you watch, you can restore the code
          below
        </div>

        <div className='text-center my-5'>
          <button
            className='btn text-red-500 underline'
            onClick={() => {
              setShowForm(true)
              setCode('')
            }}
          >
            Restore code
          </button>
          {showForm && code === '' && (
            <div className='mt-5 w-full lg:w-1/2 mx-auto p-4 my-8 bg-white border border-gray-200 rounded-lg shadow-md sm:p-6 lg:p-8 dark:bg-gray-800 dark:border-gray-700'>
              <label>Order number(you can find it in your email)</label>
              <input
                type='text'
                placeholder='Enter your order number'
                onChange={(e) => {
                  setCheckoutId(e.target.value)
                }}
                className='bg-gray-50 w-full mt-5 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 '
              />
              <button
                onClick={() => getNewCode()}
                className='mt-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
              >
                Request a new code
              </button>
            </div>
          )}
          {showForm && code !== '' && (
            <div className='mt-5 w-full lg:w-1/2 mx-auto p-4 my-8 bg-white border border-gray-200 rounded-lg shadow-md sm:p-6 lg:p-8 dark:bg-gray-800 dark:border-gray-700'>
              <div className='text-xl'> {code}</div>
            </div>
          )}
        </div>

        <dl className='mt-8 sm:mt-16 grid grid-cols-1 gap-5 sm:grid-cols-2'>
          <div className='px-4 py-5 bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden sm:p-6'>
            <dt className='text-sm font-medium text-gray-500 dark:text-gray-300 truncate'>
              Happy clients
            </dt>
            <dd className='mt-1 text-3xl font-semibold'>500K+</dd>
          </div>
          <div className='px-4 py-5 bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden sm:p-6'>
            <dt className='text-sm font-medium text-gray-500 dark:text-gray-300 truncate'>
              Rating
            </dt>
            <dd className='mt-1 text-3xl font-semibold'>4.8 </dd>
          </div>
        </dl>

        <div className='mt-10 sm:mt-8 mb-10'>
          <div className='text-3xl font-bold my-8'>Users reviews</div>
          <div className='grid grid-cols-1 sm:grid-cols-3 gap-5 bg-white dark:bg-gray-800 rounded-lg p-8'>
            <div className='flex flex-col space-y-4'>
              <div className='flex space-x-3 justify-center'>
                <StarIcon className='h-5 w-5' />
                <StarIcon className='h-5 w-5' />
                <StarIcon className='h-5 w-5' />
                <StarIcon className='h-5 w-5' />
                <StarIcon className='h-5 w-5' />
              </div>
              <div>
                That is one of the best watch faces ever. Well designed, great
                level of personalization and easy to make adjustments. Thanks
                for the great work.
              </div>
              <div className='font-bold'>Dieter Mai</div>
            </div>
            <div className='flex flex-col space-y-4'>
              <div className='flex space-x-3 justify-center'>
                <StarIcon className='h-5 w-5' />
                <StarIcon className='h-5 w-5' />
                <StarIcon className='h-5 w-5' />
                <StarIcon className='h-5 w-5' />
                <StarIcon className='h-5 w-5' />
              </div>
              <div>
                This is the watch face I've been looking for. Ideal for those of
                a certain age who's eyesight isn't what it used to be! At last I
                can see all I need without looking around for my glasses. Thanks
                again, great work.
              </div>
              <div className='font-bold'>Colin Clements</div>
            </div>
            <div className='flex flex-col space-y-4'>
              <div className='flex space-x-3 justify-center'>
                <StarIcon className='h-5 w-5' />
                <StarIcon className='h-5 w-5' />
                <StarIcon className='h-5 w-5' />
                <StarIcon className='h-5 w-5' />
                <StarIcon className='h-5 w-5' />
              </div>
              <div>
                This one should be the standard face for this watch, it’s that
                good!Keep up the good work!
              </div>
              <div className='font-bold'>Mathieu Mouraud</div>
            </div>
          </div>
          <div className='my-5'>
            <a
              className='text-indigo-500 font-bold mt-2 text-xl'
              target='_blank'
              href='https://apps.garmin.com/en-US/apps/37e8ef0a-e117-4eaf-88e4-8ef1fdc7d697'
            >
              Read all
            </a>
          </div>
        </div>
        <LagomFeatures></LagomFeatures>
        <div className='mt-10 sm:mt-8'>
          <LagomPricing />
        </div>
        <div className='lightPanel mt-10 sm:mt-16 pb-5 rounded-lg'>
          <div className='lpBlock'>
            <div className='text-3xl font-bold my-8'>Customization</div>
            <div className='instruction text-left px-5 py-12 bg-white dark:bg-gray-800 rounded-lg'>
              <div className='howTo'>
                <div className='text-lg text-center'>
                  How to customize Garmin watch face "Lagom"
                </div>
                <div className='guide flex flex-col sm:flex-row justify-center items-center'>
                  <div className='note mt-10 sm:mt-20 mb-5'>
                    <ol className='list-disc text-sm sm:text-base flex-col space-y-5 pl-5 pr-10'>
                      <li>Open the Connect IQ on your smartphone</li>
                      <li>Click on your watch icon in the top right corner</li>
                      <li>Click on "My Watch faces"</li>
                      <li>
                        Find "Lagom" watch face in your Installed folder, click
                        on it
                      </li>
                      <li>Click "Settings"</li>
                      <li>
                        Now you have screen for customization, choose different
                        color for example and click "save" in the top right
                        corner
                      </li>
                    </ol>
                  </div>
                  <div className='text-center'>
                    <img
                      src='/lagom/9.png'
                      className='rounded-lg p-10'
                      alt=''
                    />
                  </div>
                </div>
                <div className='text-center'>
                  <a
                    className='underline text-blue-400'
                    href='https://www.youtube.com/watch?v=5KTdk4-Ojm0'
                    target='_blank'
                  >
                    Youtube video about customization
                  </a>
                </div>
              </div>
            </div>

            <div className='mt-10 sm:mt-8'>
              <LagomFAQ />
            </div>
            <div className='mt-10 sm:mt-8'>
              <LagomWeather />
            </div>

            <div className='mt-10 sm:mt-8'>
              <div className='text-3xl font-bold mb-8'>Layouts example</div>
              <LagomLayout />
            </div>
            <div className='pallete my-10 sm:mt-16'>
              <div className='text-3xl font-bold my-8'>Color Pallete</div>

              <div className=''>
                <div className='flex flex-col align-middle items-center  justify-center text-left px-5 py-12 bg-white dark:bg-gray-800 rounded-lg'>
                  <div className='mb-8 text-center'>
                    Each color has a number, which will help you to find it in
                    the connect application
                  </div>
                  <a href='/lagom/garmin-pallete.png' target='_blank'>
                    <img
                      src='/lagom/garmin-pallete.png'
                      className='border-8 rounded-lg border-gray-100 dark:border-gray-700'
                      alt=''
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
