import './App.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import NavBar from './components/NavBar'
import Footer from './components/Footer'
import { Home } from './pages/Home'
import Projects from './pages/Projects'
import Blog from './pages/Blog'
import Books from './pages/Books'
import Photos from './pages/Photos'
import Lagom from './pages/Lagom'
import { NotFound } from './pages/NotFound'
import ScrollToTop from './components/ScrollTop'
import ReactGA from 'react-ga'
import React, { useEffect } from 'react'
import CookieConsent from 'react-cookie-consent'

ReactGA.initialize('UA-167553527-2')

function App() {
  const [cookieAccepted, setCookieAccepted] = React.useState(false)
  useEffect(() => {
    console.log('Cookie accepted? ' + cookieAccepted)
    if (!cookieAccepted) return
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [cookieAccepted])

  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <div className='App flex flex-col h-screen'>
          <div className=''>
            <NavBar />
          </div>
          <div className='flex-grow pt-10 pb-10 sm:pb-20 sm:pt-20 bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-white'>
            <Switch>
              <Route path='/' component={Home} exact></Route>
              <Route path='/projects' component={Projects}></Route>
              <Route path='/blog' component={Blog}></Route>
              <Route path='/books' component={Books}></Route>
              <Route path='/photos' component={Photos}></Route>
              <Route path='/lagomwf' component={Lagom}></Route>
              <Route component={NotFound}></Route>
            </Switch>
          </div>
          <div className=''>
            <Footer></Footer>
          </div>
        </div>
      </BrowserRouter>
      <CookieConsent
        enableDeclineButton
        onDecline={() => {
          setCookieAccepted(false)
        }}
        onAccept={() => {
          setCookieAccepted(true)
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  )
}

export default App
