/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Internalization',
    description:
      'English, Swedish, Nordics, Italian, Spanish, Portuguese, Slovak, German, French, Czech, Polish and Danish',
  },
  {
    name: 'Heart Rate with Zone coloring',
    description: 'Customize your profile to see relevant heart rate coloring',
  },
  {
    name: 'Tailor-made icons',
    description:
      'Do not disturb, Is Alarm On, Notification, Phone Connectivity, Garmin Weather',
  },
  {
    name: 'Custom fields',
    description:
      'Steps, Daily or Weekly distance, Daily Active Minutes, Calories, Floors, HR. Goals: Calories, Floors, Steps or Battery',
  },
  {
    name: 'Rings',
    description:
      '2 Rings with custom thikness of your choice. Goals: Calories, Floors, Steps or Battery',
  },
  {
    name: 'Date formats',
    description:
      'DAY dd.mm,DAY mm.dd,DAY dd/mm,DAY mm/dd,MM/DD/YY,DD/MM/YY, YY/MM/DD, YY/MM/DD DAY and Week number',
  },
  {
    name: 'Diverse color palette',
    description:
      'Perfectly match your watch face with the band color ( Navy, Moss, Aqua, etc..)',
  },
  {
    name: 'Always on display(AOD) support',
    description: 'And adapting screen utilization for different watch models',
  },
  {
    name: 'Privacy by design',
    description:
      'All data that is required by permissions are stored locally on your watch device. Internet connection is used only if you are providing Open Weather Map API key (Old watches).',
  },
]

export default function Example() {
  return (
    <div className=' text-left rounded-lg'>
      <div className='max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-8 lg:px-8'>
        <div className='max-w-3xl mx-auto text-center'>
          <h2 className='text-3xl font-extrabold '>
            All you need on your wrist
          </h2>
          <p className='mt-4 text-lg text-gray-500 dark:text-gray-300'>
            Lagom watch face is a simple, yet super customizable watch face to
            fill your daily need.
          </p>
        </div>
        <dl className='mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8'>
          {features.map((feature) => (
            <div key={feature.name} className='relative'>
              <dt>
                <CheckIcon
                  className='absolute h-6 w-6 text-green-500'
                  aria-hidden='true'
                />
                <p className='ml-9 text-lg leading-6 font-medium'>
                  {feature.name}
                </p>
              </dt>
              <dd className='mt-2 ml-9 text-base text-gray-500 dark:text-gray-300'>
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
