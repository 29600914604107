/* This example requires Tailwind CSS v2.0+ */
import {
  GlobeAltIcon,
  LightningBoltIcon,
  ScaleIcon,
} from '@heroicons/react/outline'

const features = [
  {
    name: '1.Big distance without icons ',
    image: '/lagom/l-1.png',
    description: [
      'Show Week Number -> false',
      'Icon 1: Top left place -> Nothing',
      'Icon 2: Top right place -> Nothing',
      'Date format -> DAY dd.mm',
      'Place 1 -> Distance daily(km/mi)',
      'Metrics -> km',
      'Place 2 -> Battery(text%)',
      'Place 3 -> Nothing',
      'Bottom place 1 -> Nothing',
      'Bottom place 2 -> Nothing',
      'Ring 1 -> Nothing',
      'Ring 2 -> Nothing',
      'Hours Color -> 15.Color pallete: Light red',
      'Place 1 Color -> 12.Color pallete: Orange',
    ],
  },
  {
    name: '2.Small battery/distance with big icons',
    image: '/lagom/l-2.png',
    description: [
      'Show Week Number -> true',
      'Icon 1: Top left place -> Nothing',
      'Icon 2: Top right place -> Nothing',
      'Date format -> DAY dd',
      'Place 1 -> Is Alarm On icon',
      'Metrics -> km',
      'Place 2 -> Is Do Not Disturb On',
      'Place 3 -> Nothing',
      'Bottom place 1 -> Distance daily(km/mi)',
      'Bottom place 2 -> Battery(Icon)',
      'Ring 1 -> Nothing',
      'Ring 2 -> Nothing',
      'Hours Color -> 53.Color pallete: Soft blue',
    ],
  },
  {
    name: '3.Big HR/Distance with small icons',
    image: '/lagom/l-3.png',
    description: [
      'Show Week Number -> true',
      'Icon 1: Top left place -> Is Do Not Disturb On',
      'Icon 2: Top right place -> Unread messages on a phone',
      'Date format -> DAY dd',
      'Place 1 -> Distance daily(km/mi)',
      'Metrics -> km',
      'Heart Rate Coloring(green-blue-red zones) -> true',
      'Your age (for HR zones caclualtion) -> {Your age}',
      'Place 2 -> HR',
      'Place 3 -> Nothing',
      'Bottom place 1 -> Battery(text%)',
      'Bottom place 2 -> Nothing',
      'Ring 1 -> Nothing',
      'Ring 2 -> Nothing',
      'Hours Color -> 29.Color pallete: Pure purple',
    ],
  },
  {
    name: '4.Rings/weather',
    image: '/lagom/l-5.png',
    description: [
      'Show Week Number -> true',
      'Icon 1: Top left place -> Weather:Condition Icons',
      'Icon 2: Top right place -> Is Do Not Disturb On',
      'Date format -> DAY dd',
      'Place 1 -> Weather:Wind direction Icon',
      'Place 2 -> Weather:Wind speed meters per second',
      'Place 3 -> Weather:Temperature',
      'Metrics -> km',
      'Temperature metrics -> Celsius',
      'Bottom place 1 -> Distance weekly(km/mi)',
      'Bottom place 2 -> Battery(text%)',
      'Ring 1 -> Battery',
      'Ring 2 -> Daily step goal progress',
      'Ring 1 thickness -> 10',
      'Ring 2 thickness -> 10',
      'Hours Color -> 11.Color pallete: Pinky Orange',
      'Ring 1 Color -> 11.Color pallete: Pinky Orange',
      'Ring 2 Color -> 6.Color pallete: Super light pink',
    ],
  },
  // {
  //   name: '2.Small battery/distance with big icons',
  //   image: '/lagom/l-2.png',
  //   description:
  //     'In addition you can see week number, distance, battery icon and two big icons',
  // },
  // {
  //   name: '3.Big HR/Distance with small icons',
  //   description:
  //     'In addition you can see week number, heart rate with zones coloring and two small icons',
  //   image: '/lagom/l-3.png',
  // },
  // {
  //   name: '4.Custom',
  //   description: 'You have 7 places where you can place data of your choice',
  //   image: '/lagom/l-4.png',
  // },
]

export default function Example() {
  return (
    <div className='py-12 bg-white dark:bg-gray-800 rounded-lg text-center '>
      <div className='mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8'>
        <h2 className='sr-only'>Layouts.</h2>
        <dl className='space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-8'>
          {features.map((feature) => (
            <div key={feature.name} className='text-center justify-center'>
              <dt>
                <div className='h-48 w-48 bg-black rounded-full p-2 border-8 mx-auto'>
                  <img src={feature?.image} className='rounded-full' alt='' />
                </div>
                <p className='mt-5 text-lg leading-6 font-medium '>
                  {feature.name}
                </p>
              </dt>
              <dd className='mt-2 text-left text-gray-500 dark:text-gray-300'>
                {feature.description.map((setting)=>(
                  <p className='text-base leading-2 text-gray-500 dark:text-gray-300'>
                    {setting}
                  </p>
                ))}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
