// import Zoom from 'react-medium-image-zoom'
/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
const files = [
  {
    title: 'Uppsala, Gamla Uppsala',
    size: 'Pixel 3a',
    source: '/content/photos/1.jpg',
  },
  {
    title: 'Stockholm, Sergels torg',
    size: 'Pixel 3a',
    source: '/content/photos/2.jpg',
  },
  {
    title: 'Stockholm',
    size: 'Pixel 3a',
    source: '/content/photos/3.jpg',
  },
  {
    title: 'Stockholm, Lappis',
    size: 'Nikon D70',
    source: '/content/photos/4.png',
  },
  {
    title: 'Stockholm, Kungsholmen',
    size: 'Nikon D70',
    source: '/content/photos/5.jpg',
  },
  {
    title: 'Stockholm, Södermalm',
    size: 'Pixel 3a',
    source: '/content/photos/6.jpg',
  },
  {
    title: 'Stockholm, Östermalm',
    size: 'Pixel 3a',
    source: '/content/photos/7.jpg',
  },
  {
    title: 'Uppsala, Centrum',
    size: 'Pixel 3a',
    source: '/content/photos/8.jpg',
  },
  {
    title: 'Stockholm, KTH',
    size: 'Pixel 3a',
    source: '/content/photos/9.jpg',
  },

  // More files...
]

export default function Photos() {
  return (
    <div className='mx-auto py-0 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-12'>
      <div className='space-y-12'>
        <div className='space-y-5 sm:space-y-4'>
          <h2 className='text-3xl font-extrabold tracking-tight sm:text-4xl'>
            Photos
          </h2>
          <p className='text-xl text-gray-500 dark:text-gray-300'>
            I like to take photos. Old Nikon D70 with 18-200 lens and Pixel 3a
            are my cameras.
          </p>
        </div>

        <ul role='list' className='grid grid-cols-1 sm:grid-cols-2 gap-5'>
          {files.map((file) => (
            <li key={file.source} className='relative'>
              <a
                href={file.source}
                target='_blank'
                className='group block w-full aspect-w-10 aspect-h-7 rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden'
              >
                <img
                  src={file.source}
                  alt=''
                  className='object-contain pointer-events-none group-hover:opacity-75'
                />
                <button
                  type='button'
                  className='absolute inset-0 focus:outline-none'
                >
                  <span className='sr-only'>View details for {file.title}</span>
                </button>
              </a>
              <p className='mt-2 block text-sm font-medium truncate pointer-events-none'>
                {file.title}
              </p>
              <p className='block text-sm font-medium text-gray-500 pointer-events-none'>
                {file.size}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
