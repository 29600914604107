/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'

const includedFeatures = [
  'Calories goals: places and rings',
  'Climbed steps goal: places and rings',
  'Calories goal: places and rings',
  'Support within 1 day',
  'Appreciation from a developer',
  'Sunrise and sunset',
  'Time to recover',
  'Respiration rate',
  'Body battery',
  'Active Minutes',
]
export default function Example() {
  const Paddle = window.Paddle
  const openCheckout = () => {
    Paddle.Checkout.open({ product: 751937 })
  }

  return (
    <div className=''>
      <div className='pt-8 sm:pt-8 lg:pt-10'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='text-center'>
            <h2 className='text-3xl font-extrabold sm:text-4xl lg:text-5xl'>
              Simple no-tricks pricing
            </h2>
            <p className='mt-4 text-xl text-gray-600'>
              Buy once, use forever. No subsciptions.
            </p>
          </div>
        </div>
      </div>
      <div className='mt-8 pb-16 sm:mt-12 sm:pb-8 lg:pb-8'>
        <div className='relative'>
          <div className='absolute inset-0 h-1/2 ' />
          <div className='relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='max-w-lg mx-auto rounded-lg overflow-hidden lg:max-w-none lg:flex'>
              <div className=' shadow-sm bg-white dark:bg-gray-800 flex-1  px-6 py-8 lg:p-12'>
                <h3 className='text-2xl font-extrabold  sm:text-3xl'>
                  Lifetime Membership
                </h3>
                <p className='mt-6 text-left text-base text-gray-500'>
                  You will get a code that unlocks all current and upcoming
                  features market as Pro. In case you will change the watch,
                  click the button below and and the new code will be shown
                  here. If you have any questions, just email me at hi@skob.io.
                </p>
                <div className='mt-8'>
                  <div className='flex items-center'>
                    <h4 className='flex-shrink-0 pr-4 text-sm tracking-wider font-semibold uppercase text-indigo-600'>
                      What's included
                    </h4>
                    <div className='flex-1 border-t-2 border-gray-200 dark:border-gray-600' />
                  </div>
                  <ul
                    role='list'
                    className='mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5'
                  >
                    {includedFeatures.map((feature) => (
                      <li
                        key={feature}
                        className='flex items-start lg:col-span-1'
                      >
                        <div className='flex-shrink-0'>
                          <CheckCircleIcon
                            className='h-5 w-5 text-green-400'
                            aria-hidden='true'
                          />
                        </div>
                        <p className='ml-3 text-sm text-gray-700 dark:text-gray-50'>
                          {feature}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='py-8 px-6 text-center bg-gray-100 dark:bg-gray-700  lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12'>
                <p className='text-lg leading-6 font-medium '>
                  Pay once, own it forever
                </p>
                <div className='mt-6'>
                  <div className='rounded-md shadow'>
                    <div
                      onClick={openCheckout}
                      className='cursor-pointer items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900'
                    >
                      Get Pro Pack
                    </div>
                  </div>
                </div>
                {/* <div className='mt-4 text-sm'>
                  <a href='#' className='font-medium '>
                    Get a free sample{' '}
                    <span className='font-normal text-gray-500'>(20MB)</span>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
