/* This example requires Tailwind CSS v2.0+ */
const books = [
  {
    name: 'The Selfish Gene',
    pages: '360',
    avgRating: '4.14',
    author: 'Dawkins, Richard',
    href: 'https://www.goodreads.com//book/show/61535.The_Selfish_Gene',
    date: 'Nov 16, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1366758096l/61535._SY75_.jpg',
  },
  {
    name: 'Театр',
    pages: '320',
    avgRating: '4.15',
    author: 'Maugham, W. Somerset',
    href: 'https://www.goodreads.com//book/show/13075487',
    date: 'Oct 18, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1440619159l/13075487._SY75_.jpg',
  },
  {
    name: 'Kill It with Fire: Manage Aging Computer Systems (and Future Proof Modern Ones)',
    pages: '220',
    avgRating: '4.20',
    author: 'Bellotti, Marianne',
    href: 'https://www.goodreads.com//book/show/54716655-kill-it-with-fire',
    date: 'Oct 08, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1612209971l/54716655._SX50_.jpg',
  },
  {
    name: 'Ночь в Лиссабоне',
    pages: '320',
    avgRating: '4.28',
    author: 'Remarque, Erich Maria',
    href: 'https://www.goodreads.com//book/show/24659019',
    date: 'Sep 19, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1422027736l/24659019._SY75_.jpg',
  },
  {
    name: '1984',
    pages: '312',
    avgRating: '4.19',
    author: 'Джордж, Орвел',
    href: 'https://www.goodreads.com//book/show/43452513-1984',
    date: 'Aug 24, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1546523249l/43452513._SY75_.jpg',
  },
  {
    name: 'Project Hail Mary',
    pages: '476',
    avgRating: '4.54',
    author: 'Weir, Andy',
    href: 'https://www.goodreads.com//book/show/54493401-project-hail-mary',
    date: 'Aug 12, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1597695864l/54493401._SY75_.jpg',
  },
  {
    name: 'Прокляты и убиты',
    pages: '832',
    avgRating: '4.43',
    author: 'Astafiev, Victor',
    href: 'https://www.goodreads.com//book/show/13638022',
    date: 'Aug 06, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1336409337l/13638022._SY75_.jpg',
  },
  {
    name: "I'm Here To Win: A World Champion's Advice for Peak Performance",
    pages: '288',
    avgRating: '3.92',
    author: 'McCormack, Chris',
    href: 'https://www.goodreads.com//book/show/10194168-i-m-here-to-win',
    date: 'Jun 21, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1327946627l/10194168._SY75_.jpg',
  },
  {
    name: 'Clean Architecture',
    pages: '432',
    avgRating: '4.24',
    author: 'Martin, Robert C.',
    href: 'https://www.goodreads.com//book/show/18043011-clean-architecture',
    date: 'Jun 10, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1471680093l/18043011._SX50_.jpg',
  },
  {
    name: 'Rich Dad, Poor Dad',
    pages: '195',
    avgRating: '4.10',
    author: 'Kiyosaki, Robert T.',
    href: 'https://www.goodreads.com//book/show/69571.Rich_Dad_Poor_Dad',
    date: 'Jun 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1388211242l/69571._SY75_.jpg',
  },
  {
    name: 'Traction',
    pages: '0',
    avgRating: '4.09',
    author: 'Wickman, Gino',
    href: 'https://www.goodreads.com//book/show/8549192-traction',
    date: 'May 21, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1278520106l/8549192._SY75_.jpg',
  },
  {
    name: 'Понедельник начинается в субботу',
    pages: '276',
    avgRating: '4.22',
    author: 'Strugatsky, Arkady',
    href: 'https://www.goodreads.com//book/show/1442235._',
    date: 'May 19, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1431169226l/1442235._SX50_.jpg',
  },
  {
    name: 'Life After Google: The Fall of Big Data and the Rise of the Blockchain Economy',
    pages: '256',
    avgRating: '3.59',
    author: 'Gilder, George',
    href: 'https://www.goodreads.com//book/show/32073021-life-after-google',
    date: 'May 12, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1531682955l/32073021._SY75_.jpg',
  },
  {
    name: 'Экономика всего. Как институты определяют нашу жизнь',
    pages: '160',
    avgRating: '4.28',
    author: 'Аузан, Александр',
    href: 'https://www.goodreads.com//book/show/19390553',
    date: 'Apr 17, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1386835480l/19390553._SX50_.jpg',
  },
  {
    name: 'How Google Works',
    pages: '286',
    avgRating: '4.04',
    author: 'Schmidt, Eric',
    href: 'https://www.goodreads.com//book/show/23158207-how-google-works',
    date: 'Mar 29, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1422538855l/23158207._SY75_.jpg',
  },
  {
    name: 'Kane & Abel (Kane & Abel, #1)',
    pages: '592',
    avgRating: '4.30',
    author: 'Archer, Jeffrey',
    href: 'https://www.goodreads.com//book/show/78983.Kane_Abel',
    date: 'Mar 21, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1388965561l/78983._SY75_.jpg',
  },
  {
    name: 'The 22 Immutable Laws of Marketing: Violate Them at Your Own Risk',
    pages: '143',
    avgRating: '4.04',
    author: 'Ries, Al',
    href: 'https://www.goodreads.com//book/show/33449.The_22_Immutable_Laws_of_Marketing',
    date: 'Mar 02, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1417627150l/33449._SY75_.jpg',
  },
  {
    name: 'The Short Story of Architecture: A Pocket Guide to Key Styles, Buildings, Elements  Materials (Architectural History Introduction, A Guide to Architecture)',
    pages: '224',
    avgRating: '4.06',
    author: 'Hodge, Susie',
    href: 'https://www.goodreads.com//book/show/43886490-the-short-story-of-architecture',
    date: 'Mar 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1556905932l/43886490._SX50_.jpg',
  },
  {
    name: 'Atomic Habits: An Easy & Proven Way to Build Good Habits & Break Bad Ones',
    pages: '319',
    avgRating: '4.36',
    author: 'Clear, James',
    href: 'https://www.goodreads.com//book/show/40121378-atomic-habits',
    date: 'Feb 11, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1535115320l/40121378._SY75_.jpg',
  },
  {
    name: "Lifespan: Why We Age—and Why We Don't Have To",
    pages: '432',
    avgRating: '4.21',
    author: 'Sinclair, David',
    href: 'https://www.goodreads.com//book/show/43723901-lifespan',
    date: 'Feb 04, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1563164003l/43723901._SY75_.jpg',
  },
  {
    name: 'The Innovators: How a Group of Hackers, Geniuses and Geeks Created the Digital Revolution',
    pages: '542',
    avgRating: '4.05',
    author: 'Isaacson, Walter',
    href: 'https://www.goodreads.com//book/show/21856367-the-innovators',
    date: 'Jan 29, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1410191571l/21856367._SY75_.jpg',
  },
  {
    name: 'How Will You Measure Your Life?',
    pages: '240',
    avgRating: '4.04',
    author: 'Christensen, Clayton M.',
    href: 'https://www.goodreads.com//book/show/13425570-how-will-you-measure-your-life',
    date: 'Jan 19, 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1328046792l/13425570._SX50_.jpg',
  },
  {
    name: 'Facebook: The Inside Story',
    pages: '592',
    avgRating: '4.10',
    author: 'Levy, Steven',
    href: 'https://www.goodreads.com//book/show/52032133-facebook',
    date: 'Jan 2021',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1572261040l/52032133._SX50_SY75_.jpg',
  },
  {
    name: "Hacking Growth: How Today's Fastest-Growing Companies Drive Breakout Success",
    pages: '320',
    avgRating: '4.04',
    author: 'Ellis, Sean',
    href: 'https://www.goodreads.com//book/show/31625067-hacking-growth',
    date: 'Dec 06, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1485462864l/31625067._SY75_.jpg',
  },
  {
    name: 'Extreme Ownership: How U.S. Navy SEALs Lead and Win',
    pages: '320',
    avgRating: '4.23',
    author: 'Willink, Jocko',
    href: 'https://www.goodreads.com//book/show/23848190-extreme-ownership',
    date: 'Oct 16, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1427163007l/23848190._SX50_.jpg',
  },
  {
    name: 'Radical Candor: Be a Kickass Boss Without Losing Your Humanity',
    pages: '246',
    avgRating: '4.08',
    author: 'Scott, Kim Malone',
    href: 'https://www.goodreads.com//book/show/29939161-radical-candor',
    date: 'Oct 05, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1482838407l/29939161._SY75_.jpg',
  },
  {
    name: 'The Everything Store: Jeff Bezos and the Age of Amazon',
    pages: '384',
    avgRating: '4.11',
    author: 'Stone, Brad',
    href: 'https://www.goodreads.com//book/show/17660462-the-everything-store',
    date: 'Jul 21, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1631792766l/17660462._SX50_.jpg',
  },
  {
    name: 'The Power of Moments: Why Certain Experiences Have Extraordinary Impact',
    pages: '320',
    avgRating: '4.09',
    author: 'Heath, Chip',
    href: 'https://www.goodreads.com//book/show/34466952-the-power-of-moments',
    date: 'Jun 20, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1506854677l/34466952._SY75_.jpg',
  },
  {
    name: 'Thinking, Fast and Slow',
    pages: '499',
    avgRating: '4.16',
    author: 'Kahneman, Daniel',
    href: 'https://www.goodreads.com//book/show/11468377-thinking-fast-and-slow',
    date: 'Jun 09, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1317793965l/11468377._SX50_.jpg',
  },
  {
    name: 'Теоретический минимум по Big Data. Всё что нужно знать о больших данных',
    pages: '300',
    avgRating: '3.69',
    author: 'Ын, Анналин',
    href: 'https://www.goodreads.com//book/show/45033474-big-data',
    date: 'May 29, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1555156403l/45033474._SX50_.jpg',
  },
  {
    name: 'The Culture Map: Breaking Through the Invisible Boundaries of Global Business',
    pages: '290',
    avgRating: '4.29',
    author: 'Meyer, Erin',
    href: 'https://www.goodreads.com//book/show/22085568-the-culture-map',
    date: 'May 20, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1488319495l/22085568._SY75_.jpg',
  },
  {
    name: 'Русская модель управления',
    pages: '384',
    avgRating: '4.22',
    author: 'Прохоров, Александр',
    href: 'https://www.goodreads.com//book/show/7813148',
    date: 'May 17, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1355002145l/7813148._SX50_.jpg',
  },
  {
    name: 'Фитнес. Гид по жизни',
    pages: '292',
    avgRating: '3.77',
    author: 'Семенихин, Денис',
    href: 'https://www.goodreads.com//book/show/24994204',
    date: 'May 14, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1424617598l/24994204._SX50_.jpg',
  },
  {
    name: 'Where Good Ideas Come from: The Natural History of Innovation',
    pages: '326',
    avgRating: '3.99',
    author: 'Johnson, Steven',
    href: 'https://www.goodreads.com//book/show/8034188-where-good-ideas-come-from',
    date: 'May 10, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1311705993l/8034188._SY75_.jpg',
  },
  {
    name: 'A Brief History of Time',
    pages: '212',
    avgRating: '4.19',
    author: 'Hawking, Stephen',
    href: 'https://www.goodreads.com//book/show/3869.A_Brief_History_of_Time',
    date: 'May 08, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1333578746l/3869._SX50_.jpg',
  },
  {
    name: 'Emotional Intelligence 2.0',
    pages: '255',
    avgRating: '3.84',
    author: 'Bradberry, Travis',
    href: 'https://www.goodreads.com//book/show/6486483-emotional-intelligence-2-0',
    date: 'May 08, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1328765863l/6486483._SX50_.jpg',
  },
  {
    name: 'Measure What Matters',
    pages: '320',
    avgRating: '4.00',
    author: 'Doerr, John E.',
    href: 'https://www.goodreads.com//book/show/39286958-measure-what-matters',
    date: 'May 04, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1521104315l/39286958._SY75_.jpg',
  },
  {
    name: 'The Silent Patient',
    pages: '325',
    avgRating: '4.12',
    author: 'Michaelides, Alex',
    href: 'https://www.goodreads.com//book/show/40097951-the-silent-patient',
    date: 'May 02, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1582759969l/40097951._SX50_.jpg',
  },
  {
    name: '21 Lessons for the 21st Century',
    pages: '372',
    avgRating: '4.16',
    author: 'Harari, Yuval Noah',
    href: 'https://www.goodreads.com//book/show/38820046-21-lessons-for-the-21st-century',
    date: 'Apr 29, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1564577305l/38820046._SY75_.jpg',
  },
  {
    name: 'The Slight Edge',
    pages: '168',
    avgRating: '4.27',
    author: 'Olson, Jeff',
    href: 'https://www.goodreads.com//book/show/590652.The_Slight_Edge',
    date: 'Apr 24, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1387667330l/590652._SY75_.jpg',
  },
  {
    name: 'Talking to Strangers: What We Should Know About the People We Don’t Know',
    pages: '388',
    avgRating: '4.02',
    author: 'Gladwell, Malcolm',
    href: 'https://www.goodreads.com//book/show/43848929-talking-to-strangers',
    date: 'Apr 23, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1549393502l/43848929._SX50_.jpg',
  },
  {
    name: 'Getting to Yes: Negotiating Agreement Without Giving In',
    pages: '224',
    avgRating: '3.93',
    author: 'Fisher, Roger',
    href: 'https://www.goodreads.com//book/show/313605.Getting_to_Yes',
    date: 'Apr 06, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1631383737l/313605._SY75_.jpg',
  },
  {
    name: 'Skin in the Game: The Hidden Asymmetries in Daily Life',
    pages: '272',
    avgRating: '3.88',
    author: 'Taleb, Nassim Nicholas',
    href: 'https://www.goodreads.com//book/show/36064445-skin-in-the-game',
    date: 'Mar 05, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1510319798l/36064445._SY75_.jpg',
  },
  {
    name: 'Отцы и дети',
    pages: '215',
    avgRating: '3.96',
    author: 'Turgenev, Ivan',
    href: 'https://www.goodreads.com//book/show/35890151',
    date: 'Feb 13, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1501546107l/35890151._SY75_.jpg',
  },
  {
    name: 'Zero to One: Notes on Startups, or How to Build the Future',
    pages: '195',
    avgRating: '4.17',
    author: 'Thiel, Peter',
    href: 'https://www.goodreads.com//book/show/18050143-zero-to-one',
    date: 'Feb 13, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1630663027l/18050143._SY75_.jpg',
  },
  {
    name: 'Economics in One Lesson: The Shortest & Surest Way to Understand Basic Economics',
    pages: '218',
    avgRating: '4.16',
    author: 'Hazlitt, Henry',
    href: 'https://www.goodreads.com//book/show/3028.Economics_in_One_Lesson',
    date: 'Feb 12, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1565397589l/3028._SX50_.jpg',
  },
  {
    name: 'Shoe Dog: A Memoir by the Creator of Nike',
    pages: '400',
    avgRating: '4.46',
    author: 'Knight, Phil',
    href: 'https://www.goodreads.com//book/show/27220736-shoe-dog',
    date: 'Jan 19, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1457284880l/27220736._SY75_.jpg',
  },
  {
    name: 'Influence: The Psychology of Persuasion',
    pages: '320',
    avgRating: '4.18',
    author: 'Cialdini, Robert B.',
    href: 'https://www.goodreads.com//book/show/28815.Influence',
    date: 'Jan 08, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1391026083l/28815._SY75_.jpg',
  },
  {
    name: 'Clean Code: A Handbook of Agile Software Craftsmanship',
    pages: '464',
    avgRating: '4.39',
    author: 'Martin, Robert C.',
    href: 'https://www.goodreads.com//book/show/3735293-clean-code',
    date: 'Jan 08, 2020',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1436202607l/3735293._SX50_.jpg',
  },
  {
    name: 'Rework',
    pages: '279',
    avgRating: '3.96',
    author: 'Fried, Jason',
    href: 'https://www.goodreads.com//book/show/6732019-rework',
    date: 'Dec 26, 2019',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1391275636l/6732019._SY75_.jpg',
  },
  {
    name: 'Designing Data-Intensive Applications',
    pages: '562',
    avgRating: '4.72',
    author: 'Kleppmann, Martin',
    href: 'https://www.goodreads.com//book/show/23463279-designing-data-intensive-applications',
    date: 'Dec 24, 2019',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1415816873l/23463279._SX50_.jpg',
  },
  {
    name: 'Nonviolent Communication: A Language of Life',
    pages: '300',
    avgRating: '4.32',
    author: 'Rosenberg, Marshall B.',
    href: 'https://www.goodreads.com//book/show/48727342-nonviolent-communication',
    date: 'Dec 2019',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1573143672l/48727342._SX50_.jpg',
  },
  {
    name: 'The Power of Habit: Why We Do What We Do in Life and Business',
    pages: '375',
    avgRating: '4.11',
    author: 'Duhigg, Charles',
    href: 'https://www.goodreads.com//book/show/12609433-the-power-of-habit',
    date: 'Dec 2019',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1545854312l/12609433._SY75_.jpg',
  },
  {
    name: "The Manager's Path: A Guide for Tech Leaders Navigating Growth and Change",
    pages: '226',
    avgRating: '4.19',
    author: 'Fournier, Camille',
    href: 'https://www.goodreads.com//book/show/33369254-the-manager-s-path',
    date: 'Nov 2019',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1484107737l/33369254._SY75_.jpg',
  },
  {
    name: 'Hooked: How to Build Habit-Forming Products',
    pages: '256',
    avgRating: '4.08',
    author: 'Eyal, Nir',
    href: 'https://www.goodreads.com//book/show/22668729-hooked',
    date: 'Oct 2019',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1407112405l/22668729._SY75_.jpg',
  },
  {
    name: 'The Upstarts: How Uber, Airbnb, and the Killer Companies of the New Silicon Valley Are Changing the World',
    pages: '384',
    avgRating: '4.01',
    author: 'Stone, Brad',
    href: 'https://www.goodreads.com//book/show/29905580-the-upstarts',
    date: 'Sep 2019',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1479331403l/29905580._SY75_.jpg',
  },
  {
    name: 'Elon Musk: Tesla, SpaceX, and the Quest for a Fantastic Future',
    pages: '392',
    avgRating: '4.21',
    author: 'Vance, Ashlee',
    href: 'https://www.goodreads.com//book/show/25541028-elon-musk',
    date: 'Aug 2019',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1518291452l/25541028._SY75_.jpg',
  },
  {
    name: 'The Psychology of Selling: Increase Your Sales Faster and Easier Than You Ever Thought Possible',
    pages: '240',
    avgRating: '4.08',
    author: 'Tracy, Brian',
    href: 'https://www.goodreads.com//book/show/115625.The_Psychology_of_Selling',
    date: 'Jul 2019',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1380857715l/115625._SY75_.jpg',
  },
  {
    name: 'Why We Sleep: Unlocking the Power of Sleep and Dreams',
    pages: '368',
    avgRating: '4.35',
    author: 'Walker, Matthew',
    href: 'https://www.goodreads.com//book/show/34466963-why-we-sleep',
    date: 'Jul 2019',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1556604137l/34466963._SY75_.jpg',
  },
  {
    name: 'The Power of Positive Leadership: How and Why Positive Leaders Transform Teams and Organizations and Change the World',
    pages: '208',
    avgRating: '4.18',
    author: 'Gordon, Jon',
    href: 'https://www.goodreads.com//book/show/32027331-the-power-of-positive-leadership',
    date: 'May 2019',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1491423333l/32027331._SY75_.jpg',
  },
  {
    name: "Red Notice: A True Story of High Finance, Murder, and One Man's Fight for Justice",
    pages: '380',
    avgRating: '4.41',
    author: 'Browder, Bill',
    href: 'https://www.goodreads.com//book/show/22609522-red-notice',
    date: 'Mar 04, 2018',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1422848658l/22609522._SY75_.jpg',
  },
  {
    name: 'Камера обскура',
    pages: '219',
    avgRating: '3.99',
    author: 'Nabokov, Vladimir',
    href: 'https://www.goodreads.com//book/show/30528163',
    date: 'Jan 2018',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1605771100l/30528163._SY75_.jpg',
  },
  {
    name: "The Lean Startup: How Today's Entrepreneurs Use Continuous Innovation to Create Radically Successful Businesses",
    pages: '299',
    avgRating: '4.10',
    author: 'Ries, Eric',
    href: 'https://www.goodreads.com//book/show/10127019-the-lean-startup',
    date: 'Jan 2018',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1629999184l/10127019._SY75_.jpg',
  },
  {
    name: 'The $100 Startup: Reinvent the Way You Make a Living, Do What You Love, and Create a New Future',
    pages: '268',
    avgRating: '3.86',
    author: 'Guillebeau, Chris',
    href: 'https://www.goodreads.com//book/show/12605157-the-100-startup',
    date: 'Jan 2018',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1345666854l/12605157._SY75_.jpg',
  },
  {
    name: 'Sapiens: A Brief History of Humankind',
    pages: '512',
    avgRating: '4.39',
    author: 'Harari, Yuval Noah',
    href: 'https://www.goodreads.com//book/show/23692271-sapiens',
    date: 'Jan 2018',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1595674533l/23692271._SY75_.jpg',
  },
  {
    name: 'На Западном фронте без перемен / Возвращение',
    pages: '480',
    avgRating: '4.59',
    author: 'Remarque, Erich Maria',
    href: 'https://www.goodreads.com//book/show/12896837',
    date: 'Sep 06, 2017',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1334353486l/12896837._SX50_.jpg',
  },
  {
    name: 'Industrial Management - Tools and Techniques',
    pages: '248',
    avgRating: '3.00',
    author: 'Engwall, Mats',
    href: 'https://www.goodreads.com//book/show/32954186-industrial-management---tools-and-techniques',
    date: 'Sep 2017',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1479047275l/32954186._SX50_.jpg',
  },
  {
    name: 'Триумфалната арка',
    pages: '453',
    avgRating: '4.42',
    author: 'Remarque, Erich Maria',
    href: 'https://www.goodreads.com//book/show/8873084',
    date: 'Jun 2017',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1281856066l/8873084._SX50_.jpg',
  },
  {
    name: 'Прощай, оружие!',
    pages: '300',
    avgRating: '3.81',
    author: 'Hemingway, Ernest',
    href: 'https://www.goodreads.com//book/show/25742398',
    date: 'May 10, 2017',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1434523245l/25742398._SX50_.jpg',
  },
  {
    name: 'Три товарища',
    pages: '300',
    avgRating: '4.49',
    author: 'Remarque, Erich Maria',
    href: 'https://www.goodreads.com//book/show/25121092',
    date: 'Feb 09, 2017',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1426090070l/25121092._SY75_.jpg',
  },
  {
    name: "Losing My Virginity: How I've Survived, Had Fun, and Made a Fortune Doing Business My Way",
    pages: '416',
    avgRating: '3.96',
    author: 'Branson, Richard',
    href: 'https://www.goodreads.com//book/show/211099.Losing_My_Virginity',
    date: 'Jan 2017',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1388216295l/211099._SY75_.jpg',
  },
  {
    name: 'Война все спишет. Воспоминания офицера-связиста 31-й армии. 1941-1945',
    pages: '272',
    avgRating: '3.99',
    author: 'Rabichev, Leonid',
    href: 'https://www.goodreads.com//book/show/25363883---31--1941-1945',
    date: 'Jan 2017',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1429094961l/25363883._SY75_.jpg',
  },
  {
    name: 'Маркетинговые войны',
    pages: '292',
    avgRating: '4.11',
    author: 'Ries, Al',
    href: 'https://www.goodreads.com//book/show/16030982',
    date: 'Sep 03, 2016',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1347879420l/16030982._SX50_.jpg',
  },
]

/* This example requires Tailwind CSS v2.0+ */

export default function Books() {
  const booksCount = books.length
  const pages = books.reduce((acc, book) => acc + parseInt(book.pages), 0)
  const stats = [
    { name: 'Books', stat: booksCount },
    { name: 'Pages', stat: pages },
  ]

  return (
    <div>
      <div className='mx-auto py-0 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-12'>
        <div className='space-y-12'>
          <div className='space-y-5 sm:space-y-4'>
            <h2 className='text-3xl font-extrabold tracking-tight sm:text-4xl'>
              Books
            </h2>
            <p className='text-xl text-gray-500 dark:text-gray-300'>
              Books that I've read so far.
            </p>
          </div>
          <div>
            <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2'>
              {stats.map((item) => (
                <div
                  key={item.name}
                  className='px-4 py-5 bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden sm:p-6'
                >
                  <dt className='text-sm font-medium text-gray-500 dark:text-gray-300 truncate'>
                    {item.name}
                  </dt>
                  <dd className='mt-1 text-3xl font-semibold'>{item.stat}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className='mt-10'>
          <ul
            role='list'
            className='gap-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 text-left'
          >
            {books.map((person) => (
              <a
                key={person.email}
                href={`${person.href}`}
                target='_blank'
                className='bg-white dark:bg-gray-800 cursor-pointer hover:scale-105 transform transition-transform block p-4 flex border border-gray-100 dark:border-gray-800 rounded-lg'
              >
                <div>
                  <div class='w-10 aspect-w-9 aspect-h-16'>
                    <img
                      className='w-full h-full object-center object-cover'
                      src={person.image}
                      alt=''
                    />
                  </div>
                </div>
                <div className='ml-3'>
                  <p className='text-sm font-medium'>{person.name}</p>
                  <p className='text-sm text-gray-500 dark:text-gray-300 mt-1'>
                    Rating: {person.avgRating}
                  </p>
                  <p className='text-sm text-gray-500 dark:text-gray-300'>
                    {person.date}
                  </p>
                </div>
              </a>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
