import React from 'react'
import { Link } from 'react-router-dom'
import { IdentificationIcon, BookOpenIcon, PencilAltIcon, CameraIcon, FireIcon, TerminalIcon } from '@heroicons/react/outline'
/* This example requires Tailwind CSS v2.0+ */
const incentives = [
  {
    name: 'Projects',
    link: '/projects',
  },
  {
    name: 'Blog',
    link: '/blog',
  },
  {
    name: 'Books',
    link: '/books',
  },
  {
    name: 'Photos',
    link: '/photos',
  },
]

export const Nav = () => {
    return (
      <div className=''>
      <div className='mx-auto py-0 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-12'>
          <div className='max-w-2xl mx-auto px-4 lg:max-w-none'>
            <a
              href='Skobeltcyn_CV.pdf'
              target='_blank'
              className='hover:shadow'
            >
              <h3 className='text-sm sm:text-x mt-5 text-indigo-400 font-bold'>
                Download CV
              </h3>
            </a>
            <div className='mt-10 grid grid-cols-1 sm:grid-cols-2 gap-5'>
              {incentives.map((incentive, index) => (
                <Link
                  to={incentive.link}
                  key={incentive.name}
                  className='cursor-pointer bg-white dark:bg-gray-800 border border-gray-100 dark:border-gray-800  flex flex-col rounded py-10 hover:scale-105 transition-transform transform'
                >
                  <div className='flex justify-center'>
                    {index === 0 && (
                      <TerminalIcon className='w-12 h-12 text-gray-700 dark:text-white'></TerminalIcon>
                    )}
                    {index === 1 && (
                      <PencilAltIcon className='w-12 h-12 text-gray-700 dark:text-white'></PencilAltIcon>
                    )}
                    {index === 2 && (
                      <BookOpenIcon className='w-12 h-12 text-gray-700 dark:text-white'></BookOpenIcon>
                    )}
                    {index === 3 && (
                      <CameraIcon className='w-12 h-12 text-gray-700 dark:text-white'></CameraIcon>
                    )}
                  </div>
                  <div className='mt-4'>
                    <h3 className='text-sm sm:text-xl font-medium'>
                      {incentive.name}
                    </h3>
                    {/* <p className='mt-2 text-sm text-gray-500'>
                      {incentive.description}
                    </p> */}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
}

