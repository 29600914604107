/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

const faqs = [
  {
    question: 'is it Free?',
    answer:
      'Yes, the core part is free. But some features are available only for the Pro version. So, purchasing the Pro version is completely optional. It helps the developer to keep being motivated to improve watch face over time for free and pro users.',
  },
  {
    question: 'How to activate Pro package?',
    answer:
      '1. Buy a Pro code, and you will get a 6-digits code in your email. 2.Open Connect IQ settings of Lagom watch face, and enter code into Pro Code. Click "Save"',
  },
  {
    question: 'Why the weather is showing N/A?',
    answer:
      'Some devices, for example, Fenix 5 do not support default weather providers. Please check instructions provided below.',
  },
  {
    question: 'Why the weather is showing - or GPS?',
    answer:
      'Please check the weather instruction below. -/GPS means that watch face can not find GPS data or valid API key.',
  },
  {
    question: 'Why seconds rings is disappearing after a while?',
    answer:
      'Your Garmin device is always trying to save battery. So if you use Seconds as Rings, it will only show in the mode when you look at your wrist. I will add seconds as custom fields, so it will be always on display for devices that support partial updates in the future.',
  },
  {
    question: 'I see the ER:1/2/3, what does that mean?',
    answer:
      'It means that something went wrong, and you should contact the developer. Please use "Contact developer" in the IQ Connect.',
  },
  {
    question:
      'I purchased the code and later change my watch. Can I still use it?',
    answer:
      'Code has a validity time.But you can contact me via hi@skob.io, and I will send you a new code. Note, that you need to use same email as your used during the purchase.',
  },
  {
    question: 'What I get by having the code?',
    answer:
      'First, you have full unlock to all existing features marked as "Pro". And all access to new things that will be developed over time. And you show the appreciation for developer work.',
  },
  {
    question: 'How is my data treated?',
    answer:
      'I do not have any access to any of your data. All data is stored on a Garmin infrastructure.',
  },
  {
    question: 'Why you need a permissions such as Background,Internet and Postioning?',
    answer:
      'First, if you do not use Open Weather Map and provide an API key or Sunset/Sunrise fields, this permission is not utilized. For others, watch face will get your recent activity location(Positioning), and make internet requests (Communication) in the background process(Background) to show you weather. Also watch need to know you recent activity location(Positioning) to calculate Sunset/Sunrise time. If you not use Open Weather Map or Sunset/Sunrise fields, there are no use of any of permissions and have 0 impact of a battery.',
  },

  // More questions...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  return (
    <div className='text-left'>
      <div className='max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8'>
        <div className='max-w-3xl mx-auto divide-y-2 divide-gray-200'>
          <h2 className='text-center text-3xl font-extrabold  sm:text-4xl'>
            Frequently asked questions
          </h2>
          <dl className='mt-6 space-y-6 divide-y divide-gray-200'>
            {faqs.map((faq) => (
              <Disclosure as='div' key={faq.question} className='pt-6'>
                {({ open }) => (
                  <>
                    <dt className='text-lg'>
                      <Disclosure.Button className='text-left w-full flex justify-between items-start text-gray-400'>
                        <span className='font-medium '>
                          {faq.question}
                        </span>
                        <span className='ml-6 h-7 flex items-center'>
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden='true'
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as='dd' className='mt-2 pr-12'>
                      <p className='text-base text-gray-500'>{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
